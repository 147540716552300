import React, { useEffect, useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
// import { Table, TableProps } from "antd";
import { render } from "react-dom";
import share from "../assets/share .png";
import pdf from "../assets/pdf .png";
import { ErrorMessage, Form, Formik } from "formik";
import { gwsPriceFormSchema } from "../pages/Schema/Schema";
import BillingDetailForms from "./BillingDetailForms";
import { triggerNotification } from "../component/Toster";
import { useLocation } from "react-router-dom";

const productNameMapping: { [key: string]: string } = {
  ssoIdp: "SSO / IDP",
  amfa: "AMFA",
  passwordManager: "Password Manager",
  accessManager: "Access Manager",
  cloudDirectory: "Cloud Directory",
  // Add other options if needed
};

const Accordian = (props: any) => {

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEdit = params.get("isEdit");
  const { isAccordionOpen, invoiceData } = props;
  const [isAddressOpen, setAddressOpen] = useState(false);
  const [activeKey, setActiveKey] = useState<any>([]);
  const [activeKeys, setActiveKeys] = useState<any>([]);

  const handleAddressOpen = () => {
    if (props.rtoValue == 'Akku') {
      setAddressOpen(true);
      setActiveKey("1");
      setTimeout(() => {
        const calculateButton = document.getElementById("akkucalculateButton");
        if (calculateButton) {
          calculateButton.click();
        }
      }, 1000);

    } else {
      if (props?.allData?.customerPrice && props?.allData?.customerPrice.length > 0) {
        setAddressOpen(true);
        setActiveKey("1");
        setTimeout(() => {
          const calculateButton = document.getElementById("calculate");
          if (calculateButton) {
            calculateButton.click();
          }
        }, 1000);
      } else {
        triggerNotification("error", "", "Please fill the required details", "topRight", "pop-color");
      }
    }
  };

  useEffect(() => {
    if (props.activeKeys.length == 0) {
      setActiveKeys([]);
      setActiveKey([]);
      setAddressOpen(false);
    } else {
      setActiveKeys(props.activeKeys);
    }
  }, [props.activeKeys]);

  useEffect(() => {
    console.log("check", props);
    if (isEdit === "true") {
      setAddressOpen(true);
      setActiveKey("1");
    }
  }, [isEdit]);

  const formatIndianCurrency = (amount: any) => {
    if (amount) {
      const formattedAmount = parseFloat(amount).toFixed(2);
      const parts = formattedAmount.split(".");
      const rupees = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "₹" + rupees + "." + parts[1];
    } else {
      return "₹ 0.00";
    }
  };
  // Function to aggregate data by SKU
  const aggregateData = (list: any) => {
    debugger;

    const aggregatedData: any = {};

    let transationType = list.transationType;

    let transationTypeValue = 0;
    if (transationType == 'new_business') {
      transationTypeValue = 20;
    }
    if (transationType == 'renewal') {
      transationTypeValue = 12;
    }
    if (transationType == 'transfer_business') {
      transationTypeValue = 5;
    }
    debugger;
    const priceGivenByGoogle = list.priceGivenByGoogle;

    list && list.skuNameList && list.skuNameList.forEach((skuObj: any, index: any) => {
      const skus = Array.isArray(skuObj.sku) ? skuObj.sku : [skuObj.sku];
      const qtys = Array.isArray(list.qtyList[index].qty) ? list.qtyList[index].qty : [list.qtyList[index].qty];
      const customerPrices = Array.isArray(list.customerPrice[index].customerPrice) ? list.customerPrice[index].customerPrice : [list.customerPrice[index].customerPrice];
      const googlePrices = Array.isArray(list.costToGoogle[index].googlePrice) ? list.costToGoogle[index].googlePrice : [list.costToGoogle[index].googlePrice];
      const _newCosttoGoogle = Array.isArray(list.costToGoogle[index].googlePrice) ? list.costToGoogle[index].googlePrice : [list.costToGoogle[index].googlePrice];

      skus.forEach((sku: any, subIndex: any) => {
        const qty = parseFloat(qtys[subIndex]) || 0;
        const customerPrice = parseFloat(customerPrices[subIndex]) || 0;
        const googlePrice = parseFloat(googlePrices[subIndex]) || 0;
        const newCosttoGoogle = parseFloat(_newCosttoGoogle[subIndex]) || 0;
        const rowNewCosttoGoogle = priceGivenByGoogle ? newCosttoGoogle : (newCosttoGoogle - newCosttoGoogle * transationTypeValue / 100);

        if (!aggregatedData[sku]) {
          aggregatedData[sku] = [];
        }

        aggregatedData[sku].push({
          qty,
          customerPrice,
          googlePrice,
          totalCustomerPrice: qty * customerPrice,
          totalCostToGoogle: qty * rowNewCosttoGoogle,
          // totalCostToGoogle: qty * googlePrice,
          // margin: customerPrice - googlePrice,
          margin: customerPrice - rowNewCosttoGoogle,
          totalMargin: qty * (customerPrice - rowNewCosttoGoogle),
          newCosttoGoogle: rowNewCosttoGoogle
        });
      });
    });

    return aggregatedData;
  };
  const avargeArpu = (row: any) => {
    let q = row.reduce((acc: any, data: any) => acc + data.qty, 0);
    let amt = row.reduce((acc: any, data: any) => acc + data.totalMargin, 0);
    // return amt / q;
    return amt / q;
  };
  const calculateTotal = (data: any, key: any) => {
    let q = data.reduce((acc: any, row: any) => acc + row.qty, 0);
    let amt = data.reduce((sum: any, row: any) => sum + (row.qty * (row[key] || 0)), 0);
    return amt / q;
  }
  const removeHyphens = (value: any) => {
    let _value = value;
    let result = _value.replace(/-/g, ' '); // Remove all hyphens
    return result;
  }
  const getTotalPrice = (licenses: any, customerGivenPrice: any) => {
    let amt = 0;
    if (licenses) {
      if (customerGivenPrice) {
        amt = licenses * customerGivenPrice;
      }
    }
    return formatIndianCurrency(amt);
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getPlan = (data: any) => {
    let value: any = '-';
    if (data) {
      if (data.type) {
        if (data.type === "plan") {
          value = data.planType ? capitalizeFirstLetter(data.planType) : '-';
        } else if (data.type === "product" && Array.isArray(data.optionsList)) {
          // If it's a product, return the array of product names
          value = data.optionsList.map((option: string) => productNameMapping[option] || option);
        }
      }
    }
    return value;
  };
  const akkulist = (list: any) => {
    let listData: any = [];
    listData = [{
      planName: getPlan(list), // Will display plan or product names
      licenses: list.licenses ? list.licenses : '-',
      customerPrice: list.CustomerGivenPrice,
      totalPrice: getTotalPrice(list.licenses, list.CustomerGivenPrice)
    }];
    return listData;
  };
  // Component to render the table
  const DataTable = ({ list, rtoValue }: any) => {
    console.log(rtoValue);
    debugger;
    if (rtoValue == 'Akku') {
      const akkulistData = akkulist(list);
      return (
        <table className="w-full priceTable ">
          <thead className="bg-white border-b rounded-t-xl border-[#C7C7C7]">
            <tr>
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Plan / Product</th> {/* 1 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Quantity</th> {/* 2 */}
              {/* <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Actual Price</th> */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Customer Price</th> {/* 3 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Total</th> {/* 3 */}
            </tr>
          </thead>
          <tbody>
            {akkulistData.map((item: any, index: number) => (
              <tr key={index} className="bg-[#E9E9E9] border-t border-[#C7C7C7] font-semibold total">
                <td className="p-4 text-[11px] xl:text-[16px]">
                  {Array.isArray(item.planName) ? (
                    item.planName.map((product: string, i: number) => (
                      <div key={i}>
                        <b>{product}</b>
                      </div>
                    ))
                  ) : (
                    <b>{item.planName}</b>
                  )}
                </td>
                <td className="p-4 text-[11px] xl:text-[16px]">
                  <b>{item.licenses}</b>
                </td>
                {/* <td className="p-4 text-[11px] xl:text-[16px]">
                  <b>{item.actualPrice}</b>
                </td> */}
                <td className="p-4 text-[11px] xl:text-[16px]">
                  <b>{item.customerPrice}</b>
                </td>
                <td className="p-4 text-[11px] xl:text-[16px]">
                  <b>{item.totalPrice}</b>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      const aggregatedData = aggregateData(list);

      let _totalProposedPrice = 0;
      let _totalCostToGoogle = 0;
      let _totalMargin = 0;
      // Calculate the total of all proposed prices
      if (aggregatedData) {
        Object.values(aggregatedData).forEach((skuData: any) => {
          skuData.forEach((row: any) => {
            _totalProposedPrice += row.totalCustomerPrice; // Assuming proposed price is `totalCustomerPrice`
            _totalCostToGoogle += row.totalCostToGoogle;
            _totalMargin += row.totalMargin;
          });
        });
      }

      return (
        <table className="w-full priceTable ">
          <thead className="bg-white border-b rounded-t-xl border-[#C7C7C7]">
            <tr>
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">SKU</th> {/* 1 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Quantity</th> {/* 2 */}

              <th className="p-4 text-left text-[#6A6A65] text-[12px] xl:text-[14px]">List Price</th> {/* 5 */}

              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Customer Price</th> {/* 3 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Cost to CNW</th> {/* 4 */}

              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Proposed Price</th> {/* 6 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Total Cost to CNW</th> {/* 7 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">ARPU/Year</th> {/* 8 */}
              <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Total Margin/Year</th> {/* 9 */}
            </tr>
          </thead>

          <tbody>
            {Object.entries(aggregatedData).map(([sku, rows]: any) => (
              <>
                {rows.map((data: any, index: any) => (
                  <tr key={`${sku}-${index}`} className=" w-[100%] bg-white">
                    <td className="p-4 text-black text-[11px] xl:text-[16px]">{removeHyphens(sku)}</td> {/* 1 */}
                    <td className="p-4 text-black text-[11px] xl:text-[16px]">{data.qty}</td> {/* 2 */}
                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.googlePrice)}</td> {/* 5 */}
                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.customerPrice)}</td> {/* 3 */}
                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.newCosttoGoogle)}</td> {/* 4 */}

                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.totalCustomerPrice)}</td> {/* 6 */}
                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.totalCostToGoogle)}</td> {/* 7 */}
                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.margin)}</td> {/* 8 */}
                    <td className="p-4 text-black text-[13px] xl:text-[16px]">{formatIndianCurrency(data.totalMargin)}</td> {/* 9 */}
                  </tr>
                ))}
                <tr key={`${sku}-total`} className="bg-[#E9E9E9] border-t border-[#C7C7C7] font-semibold total">
                  <td className="p-4">{/* 1 */}
                    <b>Total</b>
                  </td>
                  <td className="p-4  text-[11px] xl:text-[16px]">{/* 2 */}
                    <b>{rows.reduce((acc: any, data: any) => acc + data.qty, 0)}</b>
                  </td>
                  <td className="p-4 text-[11px] xl:text-[16px]">{/* 5 */}
                    {/* <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.googlePrice, 0))}</b> */}
                    <b>{formatIndianCurrency(calculateTotal(rows, 'googlePrice'))}</b>
                  </td>
                  <td className="p-4 text-[11px] xl:text-[16px]">{/* 3 */}
                    {/* <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.customerPrice, 0))}</b> */}
                    <b>{formatIndianCurrency(calculateTotal(rows, 'customerPrice'))}</b>
                  </td>
                  <td className="p-4 text-[13px] xl:text-[16px]">{/* 4 */}
                    {/* <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.newCosttoGoogle, 0))}</b> */}
                    <b>{formatIndianCurrency(calculateTotal(rows, 'newCosttoGoogle'))}</b>
                  </td>
                  <td className="p-4 text-[11px] xl:text-[16px]">{/* 6 */}
                    <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.totalCustomerPrice, 0))}</b>
                  </td>
                  <td className="p-4 text-[11px] xl:text-[16px]">{/* 7 */}
                    <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.totalCostToGoogle, 0))}</b>
                  </td>
                  <td className="p-4 text-[11px] xl:text-[16px]">{/* 8 */}
                    <b>{formatIndianCurrency(avargeArpu(rows))}</b>
                  </td>
                  <td className="p-4 text-[11px] xl:text-[16px]">{/* 9 */}
                    <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.totalMargin, 0))}</b>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          {/* Add the final row with Proposed Price Total */}
          {/* Add SubTotal, IGST, and Total Rows */}
          <tr className="bg-[#ffffff] border-t font-bold border-[#a7a1a1]">
            <td colSpan={5} className="p-4 text-left text-[11px] xl:text-[14px]"></td>
            <td className="p-4 text-left text-[14x] xl:text-[18px]">
              <b>Total</b>
            </td>
            <td className="p-4 text-[14px] xl:text-[18px]">
              <b>{formatIndianCurrency(_totalCostToGoogle)}</b>
            </td>
            <td className="p-4 text-[14px] xl:text-[18px]">
              <b>{formatIndianCurrency(_totalProposedPrice)}</b>
            </td>
            <td className="p-4 text-[14px] xl:text-[18px]">
              <b>{formatIndianCurrency(_totalMargin)}</b>
            </td>
          </tr>
        </table>
      );
    }
  }

  // console.log("datasss", props.allData.customerPrice);

  const items: CollapseProps["items"] = [
    {
      key: "0",
      label: "Quote",
      children: (
        <div>
          <div className="tabs ">{props && props.allData && <DataTable list={props.allData} rtoValue={props.rtoValue} />}</div>
          {/* {props?.allData?.customerPrice?.length == 0 && <p className=" text-red-500">fill the form</p>} */}

          <div className="flex justify-center pt-5 px-5 ">
            {" "}
            <button className="n-user-quote flex align-center px-11 xl:px-14 py-2 xl:py-3 "
              id="handleAddressOpen" name="handleAddressOpen" onClick={handleAddressOpen}>
              {" "}
              Next
            </button>
            {/* <a className="n-user flex align-center" href="https://example.com">
              {" "}
              Next{" "}
            </a> */}
          </div>
        </div>
      ),
    },
  ];
  const items1: CollapseProps["items"] = [
    {
      key: "1",
      label: "Billing Details",
      children: (
        <div className="billing">
          <BillingDetailForms {...props} />
        </div>
      ),
    },
  ];
  const onChange = (key: string | string[]) => {
    console.log(isAccordionOpen);
  };
  // const showAll = () => {
  //   setActiveKeys(0);
  // };
  // const hideAll = () => {
  //   setActiveKeys([]);
  // };
  return (
    <div>
      {/* <button onClick={showAll}>Show All</button>
      <button onClick={hideAll}>Hide All</button> */}

      <Collapse activeKey={activeKeys} className={`${!isAccordionOpen ? " pointer-events-none  opacity-[0.3] " : " pointer-events-auto"}`} onChange={onChange} items={items} />
      <Collapse activeKey={activeKey} onChange={onChange} items={items1} className={`${!isAddressOpen ? " pointer-events-none opacity-[0.3] " : " pointer-events-auto"}`} />
    </div>
  );
};

export default Accordian;
